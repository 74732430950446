<div id="seat-selection" class="h-full">
  <!-- Overlay test for Tutorial -->
  <!-- <div class="h-full z-20 absolute top-0 left-0 right-0 bottom-0 backdrop-filter backdrop-blur-2xl"></div> -->
  <!-- <div class="h-full z-20 absolute top-0 left-0 right-0 bottom-0 bg-black filter blur-2xl"></div> -->

  <!--* MAP -->
  <div
    class="bg-black"
    id="map"
    [ngClass]="[
      isMobile ? 'h-1/2' : 'map-desktop-height map',
      isMobile && !openTabs
        ? 'full-screen-map transform duration-700'
        : 'transform duration-700',
    ]">
    <app-dvm></app-dvm>
  </div>

  <!--* SEATS DATA -->
  <!-- Gradient overlay  -->
  <div
    *ngIf="!isMobile"
    id="seats-data-container"
    class="h-full absolute top-0 pointer-events-none">
    <div
      id="seats-data-side"
      class="h-full px-7 py-2 2xl:p-7 flex flex-col items-center text-white overflow-y-auto flipped-rtl pointer-events-none">
      <div class="pointer-events-auto">
        <!-- <div class="flex -ml-3.5 flex-row mb-4 justify-end">
          <button
            class="button-home flex flex-row justify-evenly items-center"
            (click)="backToLanding()">
            <span>Home</span>
            <img src="/assets/img/bx-home-alt.svg" alt="home icon" />
          </button>
        </div> -->
        <div class="w-96 -ml-3.5 flipped-ltr">
          <h1 class="mb-2 3xl:mb-3 text-2xl 3xl:text-3xl">
            Set your Search Options
          </h1>
          <div class="mb-4 3xl:mb-5">
            <!--* FILTERS -->
            <app-filters></app-filters>
            <!--* CART -->
            <app-cart></app-cart>
          </div>
          <h1 class="mb-2 3xl:mb-3 text-2xl 3xl:text-3xl">Price Balance</h1>
          <!--* SUMMARY -->
          <div id="summary" class="py-4 bg-main text-white rounded-2.5xl">
            @if (isTicketFeeEnabled()) {
              <div
                class="flex items-center justify-between bg-white bg-opacity-5 rounded-3xl mb-2 text-white text-xs font-thin gap-2 mx-2">
                <div class="py-2 ml-2">
                  <span class="mr-1">Tickets Prices</span>
                  <span class="font-bold mr-1">
                    ${{ totalSeatsPrice | number: '1.2-2' }}</span
                  >
                  +
                  <span class="mr-1">Fees</span>
                  <span class="font-bold">
                    ${{ totalSeatsFee | number: '1.2-2' }}</span
                  >
                </div>
                <div class="rounded-3xl p-2 bg-white bg-opacity-10">
                  <span class="mr-1">Total Balance:</span>
                  <span class="font-bold">
                    ${{ totalSeatsPriceWithFees | number: '1.2-2' }}</span
                  >
                </div>
              </div>
            }
            <div class="gap-x-3 flex justify-between items-center px-4">
              <div class="flex justify-center items-center">
                <div class="flex items-center gap-2 mr-2">
                  <p
                    class="flex items-center font-normal"
                    [ngClass]="{
                      'w-30': isTicketFeeEnabled(),
                      'w-10': !isTicketFeeEnabled(),
                    }">
                    Total Seats
                  </p>
                  <span class="flex items-center gap-1 w-5"
                    ><img
                      src="/assets/img/chair-white-icon.svg"
                      alt="white chair icon"
                      class="w-3" />:</span
                  >
                </div>
                <div
                  class="w-14 h-8 px-2 flex justify-center items-center border rounded-full">
                  <h5>{{ selectedSeats.length }}</h5>
                  <!-- <input type="text" [(ngModel)]="selectedSeats.length" class="w-8 text-sm font-bold text-center bg-main"> -->
                </div>
                @if (!isTicketFeeEnabled()) {
                  <div class="text-center ml-4">
                    <p
                      class="text-sm 3xl:text-base font-normal whitespace-nowrap">
                      Ticket Total:
                    </p>
                    <p class="font-semibold text-base">
                      $ {{ totalSeatsPrice | number: '1.2-2' }}
                    </p>
                  </div>
                }
              </div>
              <div>
                @if (event) {
                  <!-- <button class="btn-white w-40 font-bold disabled:opacity-20" (click)="checkout()" [disabled]="selectedSeats.length < 15">Go to Checkout</button> -->
                  <button
                    class="btn-white w-40 font-bold disabled:opacity-20"
                    (click)="checkout()"
                    [tooltip]="
                      selectedSeats.length > event.maxTickets
                        ? 'For larger groups over ' +
                          event.maxTickets +
                          ', please contact the ' +
                          configurationService.configuration.clientName +
                          ' at ' +
                          configurationService.configuration.phone
                        : configurationService.configuration.defaultTooltipText
                    "
                    [disabled]="
                      selectedSeats.length < event.minTickets ||
                      selectedSeats.length > event.maxTickets
                    ">
                    Go to Checkout
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--* SEATS DATA - Mobile -->
  <div *ngIf="isMobile" class="h-1/2 relative">
    <div
      (click)="toggleShowTabs()"
      class="mx-auto p-2 absolute -top-8 left-1/2 transform -translate-x-1/2">
      <img
        src="/assets/img/arrow-down-white-mobile-icon.svg"
        [ngClass]="
          !openTabs ? 'transform rotate-180 duration-700' : 'duration-700'
        "
        alt="Arrow down icon" />
    </div>
    <!-- Tabs -->
    <div>
      <ul class="flex text-sm text-gray-400 border-gray-100">
        <li class="flex-auto">
          <button
            id="filters-tab"
            class="w-full px-4 py-3 font-bold border-b-3"
            [ngClass]="{ 'text-black border-red': activeTab === 'filters' }"
            (click)="activeTab = 'filters'">
            How many Tickets?
          </button>
        </li>
        <li class="flex-auto">
          <button
            id="cart-tab"
            class="w-full px-4 py-3 font-bold border-b-3"
            [ngClass]="{ 'text-black border-main': activeTab === 'cart' }"
            (click)="activeTab = 'cart'">
            Your Seats
          </button>
        </li>
      </ul>
    </div>

    <!-- Tabs Content -->
    <div id="tab-content" class="bg-white overflow-y-auto">
      <div
        id="filters"
        class="h-full"
        [ngClass]="activeTab === 'filters' ? 'block' : 'hidden'">
        <!--* FILTERS -->
        <app-filters></app-filters>
      </div>
      <div
        id="cart"
        class="h-full"
        [ngClass]="activeTab === 'cart' ? 'block' : 'hidden'">
        <!--* CART -->
        <app-cart (checkoutFunc)="checkout()"></app-cart>
      </div>
    </div>
  </div>

  <!--* UPSELLS -->
  <div [hidden]="is3dOpen" id="upsells" class="absolute top-0 right-0">
    <app-upsells></app-upsells>
    <!-- <app-upsells [currentEvent]="event"></app-upsells> -->
  </div>
</div>

<!--* DVM POPOVER -->

<div id="tooltip" class="relative dvmPopover p-2 z-10" role="tooltip">
  <div id="arrow" data-popper-arrow></div>
  <!-- Placement to insert the map popovers -->
  <div #dynamicPopover></div>
</div>
