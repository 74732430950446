<ul *ngIf="seats().length !== 0" id="cart-list" class="overflow-hidden">
  <li *ngFor="let section of seatsBySection()">
    <div
      class="max-w-full px-2 py-1 flex justify-around items-center border-b border-gray-300 last:border-0 -mr-3"
      [ngClass]="isMobile ? 'w-full min-height-12' : ''">
      <div class="w-full">
        <div
          class="w-full flex items-center justify-between"
          [ngClass]="{
            'mb-2': isTicketFeeEnabled(),
          }">
          <div class="flex items-center justify-center gap-4">
            <h5 class="font-bold" [ngClass]="isMobile ? '' : 'ml-2'">
              <span class="font-normal text-gray-600 mr-1">Section</span>
              {{
                section?.original_id?.includes('_')
                  ? section.original_id?.split('_')[1].split('-')[0]
                  : section.original_id
              }}
            </h5>
            <div
              class="flex items-center justify-center"
              [ngClass]="{
                'gap-2': isTicketFeeEnabled(),
                'flex-wrap': !isTicketFeeEnabled(),
              }">
              <div
                class="w-25 min-w-max h-8 flex justify-center items-center border border-gray-300 rounded-full"
                [ngClass]="isMobile ? 'px-3 ml-3' : 'px-4'">
                <!-- <input type="text" [(ngModel)]="numberAccessibleSeats" class="w-8 text-sm  text-center text-black bg-white"> -->
                <img
                  src="/assets/img/chair-blue-icon.svg"
                  alt="main chair icon"
                  class="h-3" />
                <h5 class="min-w-max ml-1 font-bold text-main">
                  {{ section.totalSectionSeats }} seats
                </h5>
              </div>
              <ng-container
                *ngTemplateOutlet="
                  sectionPriceTemplate();
                  context: { $implicit: section }
                "></ng-container>
            </div>
          </div>
          <div class="flex ml-1">
            <button
              class="btn-icon bg-white hover:bg-gray-200 ml-1"
              (click)="removeSectionSeatsFromSelectedSeats(section)">
              <img src="/assets/img/delete-bin-icon.svg" alt="delet bin icon" />
            </button>
            <button
              (click)="goToSeatmap(section.id)"
              class="btn-icon hover:bg-gray-200 ml-1">
              <img
                src="/assets/img/location-sharp-icon.svg"
                alt="location icon"
                *ngIf="!isGASection(section.id); else gaLocationImage" />
              <ng-template #gaLocationImage>
                <img
                  src="/assets/img/location-sharp-icon-disabled.svg"
                  alt="location icon"
                  class="cursor-not-allowed"
                  *ngIf="isGASection(section.id)" />
              </ng-template>
            </button>
            <button
              class="btn-icon hover:bg-gray-200 no-rotate ml-1"
              #arrow
              (click)="toggleSectionList(section.id, arrow)">
              <img
                src="/assets/img/arrow-down-blue-icon.svg"
                alt="blue downward arrow icon" />
            </button>
          </div>
        </div>
        <ng-container
          *ngTemplateOutlet="
            sectionFeeTemplate();
            context: { $implicit: section }
          "></ng-container>
      </div>
    </div>

    <!-- <ul class="bg-gray-100 overflow-y-auto accordeon-content" [ngClass]="{hidden: !sectionsListOpen.includes(section.id)}"> -->
    <ul
      class="bg-gray-100"
      [ngClass]="{ hidden: !sectionsListOpen.includes(section.id) }">
      <li *ngFor="let row of section.rows; trackBy: trackByFn">
        <div
          class="border-b border-gray-300"
          [ngClass]="{
            'pb-3': isTicketFeeEnabled(),
          }"
          *ngFor="let seat of row.seats; let i = index">
          <div
            class="max-w-full h-14 px-2 py-1 flex justify-around items-center">
            <h5 class="font-bold" [ngClass]="isMobile ? '' : 'ml-2'">
              <span class="font-normal text-gray-500 mr-1">Section</span>
              @if (!seat.seat_row && seat.seat) {
                {{
                  section.original_id?.includes('_')
                    ? section.original_id?.split('_')[1].split('-')[0]
                    : section.original_id
                }}
              } @else {
                {{
                  seat.original_id?.includes('_')
                    ? seat.original_id.split('_')[1].split('-')[0]
                    : seat.original_id
                }}
              }
            </h5>
            <h5 class="font-bold">
              <span class="font-normal text-gray-500 ml-1 mr-1">Row</span>
              @if (seat.seat_row && seat.seat) {
                {{ seat.seat_row }}
              } @else {
                {{ 'GA' }}
              }
            </h5>
            <h5 class="font-bold">
              <span class="font-normal text-gray-500 ml-1 mr-1">Seat</span>
              @if (seat.seat_row && seat.seat) {
                {{ seat.seat }}
              } @else {
                {{ i + 1 }}
              }
            </h5>
            <div class="flex items-center">
              <button
                class="btn-icon hover:bg-gray-200"
                [ngClass]="isMobile ? 'ml-2' : 'ml-2'"
                (click)="removeOneFromSelectedSeats(seat)">
                <img
                  src="/assets/img/delete-bin-icon.svg"
                  alt="delete bin icon" />
              </button>
              <!-- No siempre llegan seat definition pa la silla por lo que si no tiene pa que enseñar nada
                  si no tiene definicioens mostramos el icono antigo de las adas. -->
              <div class="btn-icon" [ngClass]="isMobile ? 'ml-1' : 'ml-2'">
                @if (hasSeatDefinition(seat.id)) {
                  <button
                    (click)="openSeatDefinitionModal(seat.id)"
                    class="btn-icon">
                    <img
                      src="/assets/img/information-icon-blue.svg"
                      alt="location icon" />
                  </button>
                } @else {
                  <img
                    *ngIf="isAda(seat.id)"
                    src="/assets/img/accessible-icon.svg"
                    alt="accessible seating" />
                }
              </div>
              <div class="btn-icon" [ngClass]="isMobile ? 'ml-1' : 'ml-2'">
                <button
                  (click)="load3dView(seat.id)"
                  class="btn-icon bg-white hover:bg-gray-200">
                  <img src="/assets/img/feather-eye.svg" alt="location icon" />
                </button>
              </div>
            </div>
          </div>
          <ng-container
            *ngTemplateOutlet="
              seatFeeTemplate();
              context: { $implicit: seat }
            "></ng-container>
        </div>
      </li>
    </ul>
  </li>
</ul>
