<div
  id="cart"
  [ngClass]="
    isMobile ? 'h-full' : 'mb-3 3xl:mb-4 bg-white rounded-2.5xl overflow-hidden'
  ">
  <!-- header -->
  <div
    id="cart-header"
    class="w-full px-4 py-2 flex justify-between items-center bg-main-accent text-white"
    [ngClass]="[
      isMobile ? 'h-12' : 'h-14 3xl:h-16',
      isMobile && seats.length === 0 ? 'hidden' : 'block',
    ]">
    <div class="w-full">
      <h5 *ngIf="!isMobile">Your Cart</h5>
      <p class="">
        Accept 'Best Available' or Ctrl + click and drag to select your own
        seats.
      </p>
    </div>
    <button
      class="btn-white h-9 disabled:text-gray-400 disabled:bg-gray-100"
      (click)="removeAllFromSelectedSeats()"
      [disabled]="seats.length === 0">
      Remove all
    </button>
  </div>

  <!-- body -->
  <div
    id="cart-body"
    class="w-full text-black bg-white overflow-y-auto"
    [ngClass]="isMobile ? '' : 'h-52 3xl:h-44'">
    <div
      *ngIf="seats.length === 0"
      class="flex justify-center items-center px-6 py-2"
      [ngClass]="isMobile ? '' : 'h-full'">
      <h5 class="my-4 font-normal text-center text-gray-400">
        Please select number of seats and price above first.
      </h5>
    </div>

    <app-cart-body
      [isTicketFeeEnabled]="isTicketFeeEnabled()"
      [seats]="seats"
      [seatsBySection]="seatsBySection"
      [seatFeeTemplate]="seatFeeTemplate"
      [sectionFeeTemplate]="sectionFeeTemplate"
      [sectionPriceTemplate]="sectionPriceTemplate">
      <ng-template #sectionPriceTemplate let-section>
        @if (!isTicketFeeEnabled()) {
          <div [ngClass]="isMobile ? 'ml-3' : 'ml-5'">
            <h5 class="font-bold text-main">
              ${{ section.totalSectionPrice | number: '1.2-2' }}
            </h5>
          </div>
        }
      </ng-template>
      <ng-template #sectionFeeTemplate let-section>
        @if (isTicketFeeEnabled()) {
          <div
            class="flex items-center justify-between bg-main-blue01 rounded-xl p-2 mb-2 text-main-blue text-sm">
            <div>
              <span class="mr-1">Price</span>
              <span class="font-bold mr-1">
                ${{ section.totalSectionPrice | number: '1.2-2' }}</span
              >
              +
              <span class="mr-1">Fees</span>
              <span class="font-bold">
                ${{ section.totalFee | number: '1.2-2' }}</span
              >
            </div>
            <div class="font-bold">
              <span class="mr-1">Total:</span>
              <span>
                ${{ section.totalSectionPriceWithFee | number: '1.2-2' }}</span
              >
            </div>
          </div>
        }
      </ng-template>
      <ng-template #seatFeeTemplate let-seat>
        @if (isTicketFeeEnabled()) {
          <div
            class="flex items-center justify-between bg-main-blue01 rounded-xl mx-3 p-2 text-main-blue text-sm">
            <div>
              <span class="mr-1">Ticket Price</span>
              <span class="font-bold mr-1">
                ${{ seat.price | number: '1.2-2' }}</span
              >
              +
              <span class="mr-1">Fees</span>
              <span class="font-bold">
                ${{ seat.totalFee | number: '1.2-2' }}</span
              >
            </div>
            <div class="font-bold">
              <span class="mr-1">Total:</span>
              <span> ${{ seat.totalPriceWithFees | number: '1.2-2' }}</span>
            </div>
          </div>
        }
      </ng-template>
    </app-cart-body>

    <!--* SUMMARY -->
    <div id="summary" *ngIf="isMobile" class="px-4 py-2">
      <h4 class="my-4 font-bold text-center">Price Balance</h4>
      @if (isTicketFeeEnabled()) {
        <div
          class="flex flex-col items-center justify-between bg-main-blue rounded-3xl mb-2 py-2 text-white text-xs font-thin gap-2">
          <div class="py-2 ml-2">
            <span class="mr-1">Tickets Prices</span>
            <span class="font-bold mr-1">
              ${{ totalSeatsPrice | number: '1.2-2' }}</span
            >
            +
            <span class="mr-1">Fees</span>
            <span class="font-bold">
              ${{ totalSeatsFee | number: '1.2-2' }}</span
            >
          </div>
          <div class="bg-main-lightblue rounded-3xl p-2">
            <span class="mr-1">Total Balance:</span>
            <span class="font-bold">
              ${{ totalSeatsPriceWithFees | number: '1.2-2' }}</span
            >
          </div>
        </div>
      }
      <div class="px-6 py-4 bg-main text-white rounded-2.5xl">
        <div class="mb-4 flex justify-center items-center">
          <div class="mr-4 flex items-center">
            <h5 class="mr-3 flex items-center">
              Total Seats
              <span class="w-3 mx-1"
                ><img
                  src="/assets/img/chair-white-icon.svg"
                  alt="white chair icon"
                  class="w-3" /></span
              >:
            </h5>
            <div
              class="w-16 h-8 px-4 flex justify-center items-center border rounded-full">
              <h5>{{ seats.length }}</h5>
              <!-- <input type="text" [(ngModel)]="seats.length" class="w-8 text-sm font-bold text-center bg-main"> -->
            </div>
            @if (!isTicketFeeEnabled()) {
              <div class="text-center">
                <h4 class="text-sm 3xl:text-base font-normal">Ticket Total:</h4>
                <h4>$ {{ totalSeatsPrice | number: '1.2-2' }}</h4>
              </div>
            }
          </div>
        </div>
        <div>
          <!-- <button class="btn-white w-40 font-bold disabled:opacity-20" (click)="checkout()" [disabled]="seats.length < 15">Go to Checkout</button> -->
          <button
            class="btn-white w-40 mx-auto font-bold disabled:opacity-20"
            (click)="checkoutMob()"
            [disabled]="
              seats.length < minSeatsLimit || seats.length > maxSeatsLimit
            ">
            Go to Checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
