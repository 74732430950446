import {
  AfterViewInit,
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BestAvailableService } from '../digital-venue/services/best-available.service';
import { DvmService } from '../digital-venue/services/dvm.service';
import { IsolatedSeatsService } from '../digital-venue/services/isolated-seats.service';
import { SeatManagementService } from '../digital-venue/services/seat-management.service';
import { ModalsService } from '../shared/modals/modals.service';
import { SaleEvent, SeatWithDefinitions } from '../shared/models';
import {
  AvailabilityService,
  CartService,
  UtilitiesService,
} from '../shared/services';
import { SelectedSeats } from '../shared/services/cart.service';
import { ConfigurationService } from '../shared/services/configuration.service';
import { ConnectionService } from '../shared/services/connection.service';
import { TutorialService } from '../shared/services/tutorial.service';
import { UpsellsService } from './upsells/upsells.service';

@Component({
  selector: 'app-seat-selection',
  templateUrl: './seat-selection.component.html',
  styleUrls: ['./seat-selection.component.scss'],
})
export class SeatSelectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isMobile: boolean;
  activeTab: 'filters' | 'cart';
  openTabs: boolean;

  // eventId: number;
  event: SaleEvent;
  // html placement for popovers
  @ViewChild('dynamicPopover', { read: ViewContainerRef })
  public popoverPlacement: ViewContainerRef;
  configurationService = inject(ConfigurationService);
  isTicketFeeEnabled = signal(false);

  get selectedSeats() {
    return Object.values(this.cartService.selectedSeats);
  }

  get totalSeatsFee() {
    return this.cartService.selectedSeatsTotalFee;
  }

  get totalSeatsPrice() {
    return this.cartService.selectedSeatsTotalPrice;
  }

  get totalSeatsPriceWithFees() {
    return this.cartService.selectedSeatsTotalPriceWithFees;
  }

  get is3dOpen() {
    return this.dvmService.is3dOpen;
  }

  constructor(
    public bestAvailableService: BestAvailableService,
    private cartService: CartService,
    private connectionService: ConnectionService,
    private dvmService: DvmService,
    private isolatedSeatsService: IsolatedSeatsService,
    private seatManagementService: SeatManagementService,
    private modalsService: ModalsService,
    private availabilityService: AvailabilityService,
    private upsellsService: UpsellsService,
    private utilitiesService: UtilitiesService,
    private activatedRoute: ActivatedRoute,
    private tutorialService: TutorialService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.availabilityService.seatsAvailability = {};
    this.availabilityService.sectionsOnlyAdas = {};
    this.availabilityService.sectionsAvailability = {};
    this.bestAvailableService.selectedSectionsForBA = [];
    this.seatManagementService.unselectAllSelectedSeatsWithoutLoadingMap();
  }

  ngAfterViewInit(): void {
    // set popover placement once the view has loaded
    this.dvmService.popoverPlacement = this.popoverPlacement;
  }

  ngOnInit(): void {
    // Device detection
    this.event = this.activatedRoute.snapshot.data['event'];

    this.isMobile = this.utilitiesService.isMobile;
    this.activeTab = 'filters';
    this.openTabs = true;

    // Tutorial check
    this.tutorialService.openTutorialForNewUser(this.event);

    const tutorial$ = this.tutorialService.tutorial$.subscribe(
      tutorialConfig => {
        if (tutorialConfig.activeStepCommand === 'activateMobileCartTab') {
          this.activeTab = 'cart';
        }

        if (
          tutorialConfig.activeStepCommand === 'activateMobileFiltersTab' ||
          tutorialConfig.isTutorialEnded
        ) {
          this.activeTab = 'filters';
        }
      }
    );
    this.dvmService.viewerSubscriptions.push(tutorial$);

    // console.log(this.cartService.selectedSeats);
    this.cartService.resetCart();

    if (this.event?.upsells) {
      this.upsellsService.upsells = this.event.upsells;
    }
    if (this.event.minTickets && this.event.maxTickets) {
      this.availabilityService.minSeatsLimit = this.event.minTickets;
      this.availabilityService.maxSeatsLimit = this.event.maxTickets;
    }
    this.connectionService.getCurrentClient().subscribe(response => {
      this.isTicketFeeEnabled.set(response.client.isTicketFeeEnabled);
    });
  }

  checkout() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    if (
      this.selectedSeats.length >= this.event.minTickets &&
      this.selectedSeats.length <= this.event.maxTickets
    ) {
      const checkIsolatedSeats = () => {
        let isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats(
          this.selectedSeats.map(a => a.id)
        );
        isolatedSeats = [...new Set(isolatedSeats)]; // Remove duplicates
        // translate to mmc id
        const mmcNode = isolatedSeats.map(item =>
          this.utilitiesService.tdcToMmcTranslator.get(item)
        );
        if (isolatedSeats && isolatedSeats.length) {
          // Modal
          const modalData = {
            title: "Wait! There's a problem",
            content: `The following seats are left isolated with your selection: ${mmcNode.map(a => a).toString()}.
            Please, fix your selection before continuing to checkout.`,
            acceptBtnName: 'CLOSE',
          };
          this.modalsService.openModal(modalData);
          // Isolated seats
          this.isolatedSeatsService.isolatedSeatsActive = true;
          this.isolatedSeatsService.lastIsolatedSeats = isolatedSeats;
          this.dvmService.viewer.addNodesToGroup(isolatedSeats, 'pending');
        } else {
          const seatsWithRestrictions = this.checkRestrinctions(
            this.cartService.selectedSeats
          );
          if (seatsWithRestrictions.length > 0) {
            const toCheckout = this.goToCheckout;
            const gtCheckout = toCheckout.bind(this);
            this.modalsService.openRestrictedSeatsModal({
              func: gtCheckout,
              seats: seatsWithRestrictions,
            });
          } else {
            this.goToCheckout();
          }
        }
      };

      if (
        this.dvmService.viewer.getMapId() !== this.dvmService.loadOptions.map_id
      ) {
        this.dvmService.loadMap().then(checkIsolatedSeats);
      } else {
        checkIsolatedSeats();
      }
    }
  }

  checkRestrinctions(seatsFromCart: SelectedSeats): any {
    const list: SeatWithDefinitions[] = Object.entries(seatsFromCart).map(
      value => {
        const [id, seat] = value;
        const [section, _] = id.split('-');
        const data = this.availabilityService.seatsAvailability[section][id];
        return { ...seat, definitions: data.seatDefinition };
      }
    );
    const result = list.filter(value => {
      let r = value.definitions.filter(item => item.restriction);
      return r.length > 0;
    });
    return result;
  }

  goToCheckout() {
    this.connectionService
      .createTransaction(
        this.event.pvEventId,
        Object.keys(this.cartService.selectedSeats)
      )
      .subscribe({
        next: response => {
          if (response['result'].id && response['result'].token) {
            this.router.navigate(['/', 'checkout', response['result'].id], {
              queryParams: { token: response['result'].token },
            });
          }
        },
        error: error => {
          console.error(error);
          console.error(error.error.message);
          const modalData = {
            title: 'ERROR',
            content:
              'An Error occurred while trying to create the Transaction.',
            acceptBtnName: 'CLOSE',
            // acceptFunction: ,
          };
          // If there's a custom api error.
          if (error.error.hasOwnProperty('code')) {
            modalData.content = error.error.message;
          }
          this.modalsService.openModal(modalData);
        },
      });
  }

  toggleShowTabs() {
    this.openTabs = !this.openTabs;
  }

  backToLanding() {
    this.router.navigate(['/events']);
  }
}
