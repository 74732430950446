import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UpsellsService } from '../seat-selection/upsells/upsells.service';
import { ModalsService } from '../shared/modals/modals.service';
import {
  Seat,
  SeatsBySection,
  TransactionWrapper,
  Upgrade,
  Upsell,
} from '../shared/models';
import { PriceWithQuantity } from '../shared/models/transaction.model';
import { UtilitiesService } from '../shared/services';
import { ConfigurationService } from '../shared/services/configuration.service';
import { ConnectionService } from '../shared/services/connection.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  isMobile: boolean;
  isUpgrade: boolean;
  checkedBox: string = '0';
  transactionId: string;
  transaction: TransactionWrapper;

  seats: Seat[];
  totalTransactionSeats: number;

  seatsByPrice: PriceWithQuantity[];
  seatsBySections: Record<string, SeatsBySection>;
  sortedSeatsBySectionArray: SeatsBySection[];
  sortedSeatsBySectionAndRowArray: SeatsBySection[];
  seatsForTable: SeatsBySection[];
  selectedBuyerTypeId: number;
  isRainout: boolean;

  upgradeSelected: Upgrade;

  get rainoutValue() {
    if (!this.transaction) {
      return null;
    } else {
      return this.transaction.transaction.tdc_transaction.hasInsuranceRainOut;
    }
  }
  set rainoutValue(value) {
    this.transaction.transaction.tdc_transaction.hasInsuranceRainOut = value;
  }

  showRainoutInfo: boolean = false;

  nowDate: number = Date.now();
  eventDate: number;
  deadlineInMilliseconds: number;

  get acquiredUpsells(): Upsell[] {
    return this.upsellsService.acquiredUpsells;
  }

  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private activatedRoute: ActivatedRoute,
    private modalsService: ModalsService,
    public configurationService: ConfigurationService,
    private upsellsService: UpsellsService,
    private utilitiesService: UtilitiesService
  ) {}

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    console.log(this.activatedRoute.snapshot);
    this.transactionId = this.activatedRoute.snapshot.params['id'];
    this.isRainout = this.configurationService.configuration.rainout;
    this.isUpgrade = this.configurationService.configuration.upgrades;
    this.connectionService.token =
      this.activatedRoute.snapshot.queryParams['token'];

    this.connectionService.getTransactionById(this.transactionId).subscribe({
      next: (response: TransactionWrapper) => {
        this.transaction = response;

        this.onTransactionChange(); // calculate seats by row and so on
        // the first time put the value to null to force the user to select one
        if (!this.transaction.transaction.tdc_transaction.hasInsuranceRainOut) {
          this.transaction.transaction.tdc_transaction.hasInsuranceRainOut =
            null;
        }
        // Rainout deadline
        this.eventDate = new Date(
          this.transaction.transaction.event.date
        ).getTime();
        this.deadlineInMilliseconds = 21 * 24 * 60 * 60 * 1000; // 21 days
        // console.log(this.nowDate);
        // console.log(this.eventDate);

        // Upsells
        this.upsellsService.upsells =
          this.transaction.transaction.event.upsells;
        this.upsellsService.checkCartSeatsForUpsellsList(
          this.totalTransactionSeats
        );

        // TODO: Build checkout based on this response...
        // TODO: Check expired transactions, modal and redirect to seat selection.
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Transaction.',
          acceptBtnName: 'CLOSE',
          acceptFunction: this.onTransactionExpiredAction.bind(this),
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        }
        this.modalsService.openModal(modalData);
      },
    });

    // if (this.cartService.selectedSeats) {
    //   this.seats = Object.values(this.cartService.selectedSeats);
    // } else {
    //   this.seats = [];
    // };
  }

  onRainoutChange(rainoutValue) {
    // if rainout no and we have rainout price

    if (!rainoutValue) {
      // First time selecting rainout "no", just change local state
      // to allow progressing the transaction
      if (
        !this.transaction?.transaction?.tdc_transaction?.insueranceRainOutPrice
      ) {
        this.transaction.transaction.tdc_transaction.hasInsuranceRainOut =
          false;
        return;
      }

      this.connectionService
        .deleteRainoutInsuranceToTransaction(this.transactionId)
        .subscribe({
          next: data => {
            this.transaction.transaction = data['result'];
            this.onTransactionChange();
          },
          error: error => {
            this.rainoutValue = true;
            console.error(error);
            // console.error(error.error.message);
            const modalData = {
              title: 'ERROR',
              content:
                'An Error occurred while trying to delete Rainout Insurance, please try again.',
              acceptBtnName: 'CLOSE',
              // acceptFunction: ,
            };
            // If there's a custom api error.
            if (error.error.hasOwnProperty('code')) {
              modalData.content = error.error.message;
            }
            this.modalsService.openModal(modalData);
          },
        });
    }
    if (rainoutValue) {
      this.connectionService
        .addRainoutInsuranceToTransaction(this.transactionId)
        .subscribe({
          next: data => (this.transaction.transaction = data['result']),
          error: error => {
            this.rainoutValue = null;
            console.error(error);
            // console.error(error.error.message);
            const modalData = {
              title: 'ERROR',
              content:
                'An Error occurred while trying to add Rainout Insurance, please try again.',
              acceptBtnName: 'CLOSE',
              // acceptFunction: ,
            };
            // If there's a custom api error.
            if (error.error.hasOwnProperty('code')) {
              modalData.content = error.error.message;
            }
            this.modalsService.openModal(modalData);
          },
        });
    }
  }

  proceedToPayment() {
    this.router.navigate(['/', 'payment', this.transactionId], {
      queryParams: { token: this.connectionService.token },
    });
    // if (this.rainoutValue) {
    //   this.connectionService.addRainoutInsuranceToTransaction(this.transactionId).subscribe({
    //     next: response => {
    //       console.log('RAINOUT INSURANCE RESPONSE', response);
    //       this.router.navigate(['/', 'payment', this.transactionId], {queryParams: {'token': this.connectionService.token}});
    //     },
    //     error: error => {
    //       console.error(error);
    //       const modalData = {
    //         title: "ERROR",
    //         content: 'An Error occurred while trying to add Rainout Insurance to Transaction.',
    //         acceptBtnName: 'CLOSE',
    //         // acceptFunction: ,
    //       };
    //       // If there's a custom api error.
    //       if (error.error.hasOwnProperty('code')) {
    //         modalData.content = error.error.message;
    //       };
    //       this.modalsService.openModal(modalData);
    //     }
    //   });
    // } else {
    //   this.router.navigate(['/', 'payment', this.transactionId], {queryParams: {'token': this.connectionService.token}});
    // }
  }

  onChangeUpgrade(event, radio: HTMLInputElement) {
    let buyerTypeId = parseInt(radio.value, 10);
    let actualBuyerType = this.transaction.transaction.buyer_type_id;
    if (actualBuyerType !== buyerTypeId) {
      let buyerTypeName = '';
      radio.checked = true;
      // get the buyer type name to send with buyer type id
      for (const buyerTypeInfo of this.transaction.transaction.event.upgrades.values()) {
        if (buyerTypeInfo.buyerTypeId == buyerTypeId) {
          buyerTypeName = buyerTypeInfo.buyerTypeName;
        }
      }
      // put the same rainout insurance value again to take into account the user has not selected yes or no
      const previousRainoutValue =
        this.transaction.transaction.tdc_transaction.hasInsuranceRainOut;
      this.editBuyerType(previousRainoutValue, buyerTypeId, buyerTypeName);
    } else {
      let defaultBuyerTypeId =
        this.transaction.transaction.event.defaultBuyerType;
      let defaultBuyerTypeName =
        this.transaction.transaction.event.defaultBuyerTypeName;
      const previousRainoutValue =
        this.transaction.transaction.tdc_transaction.hasInsuranceRainOut;
      // request to api
      this.editBuyerType(
        previousRainoutValue,
        defaultBuyerTypeId,
        defaultBuyerTypeName
      );
      this.selectedBuyerTypeId = 0;
      radio.checked = false;
    }

    return;
  }

  restartTransaction() {
    this.connectionService.deleteTransaction(this.transactionId).subscribe({
      next: response => {
        this.goSeatSelection();
      },
      error: error => {
        // no need for a modal here
        this.goSeatSelection();
      },
    });
  }

  goSeatSelection() {
    // let eventId = 10145 // todo hardcoded
    let eventId: number;
    if (this.transaction) {
      eventId = this.transaction.transaction.event.pvEventId;
    }
    this.router.navigate(['seat-selection'], {
      queryParams: { event: eventId },
    });
  }

  onTransactionExpired() {
    const modalData = {
      title: 'Transaction expired',
      content: 'The transaction has expired, please try again.',
      acceptBtnName: 'CLOSE',
      acceptFunction: this.onTransactionExpiredAction.bind(this),
    };
    this.modalsService.openModal(modalData);
  }

  onTransactionExpiredAction() {
    this.connectionService.deleteTransaction(this.transactionId).subscribe({
      next: response => {
        this.goSeatSelection();
      },
      error: error => {
        // no need for a modal here
        this.goSeatSelection();
      },
    });
  }
  // executed when we retrieve the transaction again
  onTransactionChange() {
    //! Provisional data for checkout templating
    this.seats = [];
    this.seatsByPrice = [];
    this.upgradeSelected = null;
    const pricesHolder = {};
    // create structure for frontend
    // TODO: change this access to mmcID
    for (const [mmcId, price] of Object.entries(
      this.transaction.transaction.seatsPrices
    )) {
      let seat = this.utilitiesService.splittedSeatInfoFromId(mmcId) as Seat;
      seat.price = price.value;
      this.seats.push(seat);
      if (!pricesHolder[seat.price]) {
        pricesHolder[seat.price] = { price: seat.price, quantity: 1 };
      } else {
        pricesHolder[seat.price].quantity += 1;
      }
    }
    this.seatsByPrice = Object.values(pricesHolder) as [];
    // console.log('Seats checkout: ', this.seats);
    this.totalTransactionSeats = this.seats.length;

    this.seatsBySections = this.utilitiesService.distributeSeatsBySection(
      this.seats
    );

    this.sortedSeatsBySectionArray =
      this.utilitiesService.sortSectionsBySeatsQuantity(this.seatsBySections);
    this.sortedSeatsBySectionAndRowArray =
      this.utilitiesService.addRowDsitribution(this.sortedSeatsBySectionArray);
    this.sortedSeatsBySectionAndRowArray.forEach(section => {
      section.rows = Object.values(section.rows);
    });

    this.seatsForTable = this.utilitiesService.addRangeSeats(
      this.sortedSeatsBySectionAndRowArray
    );
    for (const upgradeInfo of this.transaction.transaction.event.upgrades.values()) {
      if (
        upgradeInfo.buyerTypeId == this.transaction.transaction.buyer_type_id
      ) {
        this.upgradeSelected = upgradeInfo;
      }
    }
  }

  private editBuyerType(
    previousRainoutValue,
    buyerTypeId: number,
    buyerTypeName: string
  ) {
    this.connectionService
      .editTransactionBuyerType(this.transactionId, buyerTypeId, buyerTypeName)
      .subscribe({
        next: data => {
          this.transaction.transaction = data['result'];
          // put the same rainout insurance value again to take into account the user has not selected yes or no
          this.transaction.transaction.tdc_transaction.hasInsuranceRainOut =
            previousRainoutValue;
          this.onTransactionChange();
        },
        error: error => {
          console.error(error);
          // console.error(error.error.message);
          const modalData = {
            title: 'ERROR',
            content: 'An Error occurred while trying to change upgrades',
            acceptBtnName: 'CLOSE',
            // acceptFunction: ,
          };
          // If there's a custom api error.
          if (error.error.hasOwnProperty('code')) {
            modalData.content = error.error.message;
          }
          if (
            error.error.hasOwnProperty('code') &&
            error.error.code === 'E_TRANSACTION_RESTART'
          ) {
            modalData['acceptFunction'] = () => {
              this.restartTransaction();
            };
          }
          this.modalsService.openModal(modalData);
        },
      });
  }

  getEnvironment(): boolean {
    return environment.production;
  }
  // goTo(goPage: string) {
  //   this.router.navigate(['/', goPage]);
  // }

  // backTo(backPage: string) {
  //   this.router.navigate(['/', backPage]);
  // }

  // In your component
  getClassObject() {
    return {
      'w-full px-5 py-7': this.isMobile,
      'max-w-max px-20 py-14 mx-auto': !this.isMobile,
      'flex flex-col h-full': this.configurationService.configuration.rainout,
    };
  }
}
