import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { ModalsService } from '../../shared/modals/modals.service';
import { Seat, SeatsBySection } from '../../shared/models';
import {
  AvailabilityService,
  CartService,
  UtilitiesService,
} from '../../shared/services';
import { UpsellsService } from '../upsells/upsells.service';
import { ConnectionService } from 'src/app/shared/services/connection.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  isMobile: boolean;
  isTicketFeeEnabled = signal(false);
  modalService = inject(ModalsService);
  private availabilityService = inject(AvailabilityService);
  private cartService = inject(CartService);
  private connectionService = inject(ConnectionService);
  private dvmService = inject(DvmService);
  private seatManagementService = inject(SeatManagementService);
  private tutorialService = inject(TutorialService);
  private upsellsService = inject(UpsellsService);
  private utilitiesService = inject(UtilitiesService);
  @Output() checkoutFunc = new EventEmitter();

  get seats(): Seat[] {
    return Object.values(this.cartService.selectedSeats);
  }

  get totalSeatsFee() {
    return this.cartService.selectedSeatsTotalFee;
  }

  get totalSeatsPrice() {
    return this.cartService.selectedSeatsTotalPrice;
  }

  get totalSeatsPriceWithFees() {
    return this.cartService.selectedSeatsTotalPriceWithFees;
  }

  minSeatsLimit: number;
  maxSeatsLimit: number;

  seatsBySection: SeatsBySection[];
  // get seatsBySection(): SeatsBySection[] {
  //   console.log('selected Seats by Section: ', this.cartService.sortedSelectedSeatsBySectionArray[0].seats);
  //   return Object.values(this.cartService.sortedSelectedSeatsBySectionArray);
  // };

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;

    this.minSeatsLimit = this.availabilityService.minSeatsLimit;
    this.maxSeatsLimit = this.availabilityService.maxSeatsLimit;

    this.cartService.sortedSelectedSeatsBySectionArray$.subscribe({
      next: response => {
        let sortedSeatsBySectionAndRowArray =
          this.utilitiesService.addRowDsitribution(response);

        sortedSeatsBySectionAndRowArray.forEach(section => {
          section.rows = Object.values(section.rows);
          section.rows.forEach(row => {
            row.seats = Object.values(row.seats).sort((a: Seat, b: Seat) => {
              return +a.seat - +b.seat;
            });
          });
        });
        this.seatsBySection = sortedSeatsBySectionAndRowArray;

        // console.log('Seats By Section: ', this.seatsBySection);
      },
      error: error => console.error(),
    });

    //* UPSELLS
    // Setting initial seats for Upsells to show next benefits list
    setTimeout(() => {
      this.upsellsService.totalCartAndFiltersSeats$.next(this.minSeatsLimit);
      this.upsellsService.checkCartSeatsForUpsellsList(this.minSeatsLimit);
      // this.upsellsService.totalSeats.next(this.seats.length);
    }, 300);

    const tutorial$ = this.tutorialService.tutorial$.subscribe(
      tutorialConfig => {
        if (tutorialConfig.activeStepCommand === 'activateCheckoutButton') {
          this.minSeatsLimit = 1;
          this.maxSeatsLimit = Infinity;
        }

        if (tutorialConfig.isTutorialEnded) {
          this.minSeatsLimit = this.availabilityService.minSeatsLimit;
          this.maxSeatsLimit = this.availabilityService.maxSeatsLimit;
        }
      }
    );

    this.connectionService.getCurrentClient().subscribe(response => {
      this.isTicketFeeEnabled.set(response.client.isTicketFeeEnabled);
    });

    this.dvmService.viewerSubscriptions.push(tutorial$);
  }

  checkoutMob() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.checkoutFunc.emit();
  }

  removeAllFromSelectedSeats() {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.unselectAllSelectedSeats();
  }
}
