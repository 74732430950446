<div
  *ngIf="!confirmationPage"
  id="payment"
  class="h-full bg-gray-100 overflow-y-auto">
  <!-- Back Button -->
  <div *ngIf="!isMobile" class="m-5 mb-3">
    <button class="btn-white" (click)="goCheckout()">
      <span class="mr-4"
        ><img src="/assets/img/arrow-left-black-icon.svg" alt=""
      /></span>
      Back
    </button>
  </div>

  <div
    [ngClass]="
      isMobile ? 'w-full px-5 py-7' : 'w-288 2xl:w-352 px-20 py-14 pt-0 mx-auto'
    ">
    <div [ngClass]="isMobile ? 'mb-7 text-center' : 'mb-3'">
      <h1 class="text-4xl" [ngClass]="{ 'mb-1': isMobile }">Payment</h1>
      <div class="flex flex-row justify-between items-baseline mt-3">
        <app-timer
          (transactionExpired)="onTransactionExpired()"
          [createdTime]="this.transaction?.transaction.created_at"></app-timer>
        <app-okta-container
          (patronEmmiter)="oktaEventEmitter($event)"></app-okta-container>
      </div>
    </div>
    <!-- IF PATRON IS LOGGED WITH OKTA, SHOW IT -->
    <div
      *ngIf="patron"
      [ngClass]="isMobile ? 'flex-col gap-y-7' : 'flex-row'"
      class="w-100 flex box-white px-6 py-4 mb-3">
      <!-- ACCOUNT CREDIT COLUMN-->
      <div
        [ngClass]="isMobile ? 'w-full' : ' w-1/2'"
        class="flex flex-col account-credit-col">
        <div class="title">Account Credit</div>
        <div [ngClass]="isMobile ? 'mb-2' : 'mb-5'" class="sub-title">
          <b
            >Your account’s available credit:
            {{ patron.accountCredit | currency: 'USD' : 'symbol' : '1.2-2' }}</b
          >
          <br />
          Please select the amount you would like to use:
        </div>
        <div
          [ngClass]="
            isMobile
              ? 'flex-col gap-y-2  items-baseline'
              : 'flex-row  items-center'
          "
          class="w-full flex">
          <div
            [ngClass]="isMobile && 'flex justify-center items-center w-full'"
            class="gap-x-3 flex mr-5">
            <span class="flex justify-center items-center">$</span>
            <input
              type="text"
              class="selector-filter flex"
              (blur)="formatForDisplay()"
              (ngModelChange)="onCreditsChange($event)"
              [(ngModel)]="displayCredits" />
          </div>
          <div class="flex gap-x-4">
            <input
              type="checkbox"
              name="payInFull"
              [(ngModel)]="paymentDetails.accountCredit.payFull"
              (change)="checkboxPayInFullChange($event)" />
            <span>Use all available credit</span>
          </div>
        </div>
      </div>
      <!-- DIGITAL WALLET COLUMN-->
      <div
        [ngClass]="isMobile ? 'w-full' : ' ml-10 w-1/2'"
        class="flex flex-col digital-wallet-col">
        <div class="title">Payment Options</div>
        <div class="sub-title mb-5">
          <b
            >Total amount due:
            {{
              transaction?.transaction.tdc_transaction.balance
                | currency: 'USD' : 'symbol' : '1.2-2'
            }}</b
          >
          <br />
          Credit applied:
          {{ showCreditsApplies | currency: 'USD' : 'symbol' : '1.2-2' }} <br />
          Remaining balance:
          {{ substractCreditsToBalance | currency: 'USD' : 'symbol' : '1.2-2' }}
          <br />
          Please select the digital wallet card you would like to use:
        </div>
        <div>
          <select
            [(ngModel)]="paymentDetails.digitalWallet"
            (change)="onChangeDigitalWalletSelector($event.target)"
            name="digitalWallet"
            class="selector-filter w-full">
            <option [ngValue]="null">Select your Digital wallet</option>
            <option
              [ngValue]="item"
              *ngFor="let item of patron.digitalWalletList">
              {{ item.nickname }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- Payment Options -->
    <div
      class="box-white w-full"
      [ngClass]="isMobile ? 'px-4 py-2' : 'px-6 py-4 payment-box'">
      <form #paymentForm="ngForm">
        <div class="flex" [ngClass]="isMobile ? 'flex-wrap' : 'h-full'">
          <div
            class="my-2"
            [ngClass]="isMobile ? '' : 'w-1/2 h-auto flex flex-col'">
            <!-- Checkout Recap -->
            <div class="mb-6">
              <div
                class="px-4 py-3 bg-gray-200 rounded-lg"
                [ngClass]="isMobile ? 'mb-6' : 'mb-8'">
                <h4 class="font-bold">
                  Total amount due: ${{
                    transaction?.transaction.tdc_transaction.balance
                      | number: '1.2-2'
                  }}
                </h4>
              </div>
              <div class="w-full mb-4">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Seats</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction.totalPriceSeats
                        | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
              <div class="w-full mb-4">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Total ticket fees</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction.serviceCharges
                        | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
              <div
                class="w-full mb-4"
                *ngIf="transaction?.transaction.tdc_transaction.amusementTax">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Amusement tax (12% of seat cost):</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction.amusementTax
                        | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
              <div
                class="w-full mb-4"
                *ngIf="
                  transaction?.transaction.tdc_transaction.hasInsuranceRainOut
                ">
                <div
                  class="px-3 py-2 mb-2 text-xs bg-main-off-white rounded-lg flex justify-between items-center">
                  <p>Rainout insurance</p>
                  <p class="font-bold text-main">
                    ${{
                      transaction?.transaction.tdc_transaction
                        .insueranceRainOutPrice | number: '1.2-2'
                    }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Terms&Conditions -->
            <div [ngClass]="isMobile ? 'mb-4' : 'mb-2'">
              <div class="flex items-center mb-1">
                <input
                  type="checkbox"
                  class="w-4 h-4"
                  placeholder=""
                  id="marketing"
                  name="marketing"
                  [value]="true"
                  [(ngModel)]="paymentDetails.acceptedTerms" />
                <label
                  for="marketing"
                  class="ml-2 text-xs text-gray-600 font-bold"
                  >I agree to the following</label
                >
              </div>

              <app-legal-terms
                [legalTextData]="
                  this.configurationService.configuration.legalText
                " />
            </div>
            <!-- Extra Checkbox if exist in cfg client -->
            <div
              *ngIf="
                configurationService.configuration.extraCheckbox &&
                configurationService.configuration.extraCheckbox.active
              "
              [ngClass]="isMobile ? 'mb-4' : 'mb-2'"
              class="mt-2">
              <div class="flex items-center mb-1">
                <input
                  type="checkbox"
                  class="w-4 h-4"
                  placeholder=""
                  id="extra-checkbox"
                  name="extra-checkbox"
                  [value]="true"
                  [(ngModel)]="paymentDetails.extraCheckbox" />
                <label
                  for="extra-checkbox"
                  class="ml-2 text-xs text-gray-600 font-bold"
                  >I agree to the following</label
                >
              </div>
              <div
                class="h-32 italic text-gray-600 overflow-y-auto"
                [innerHTML]="
                  configurationService.configuration.extraCheckbox.text
                "></div>
            </div>
          </div>

          <!-- Guest/Customer data -->
          <div
            id="braintree"
            [ngClass]="isMobile ? 'mb-2 w-full' : 'w-1/2 ml-10 mb-4'">
            <div [ngClass]="{ 'mb-2': isMobile }">
              <div [ngClass]="isMobile ? '' : 'mb-4 flex'">
                <div class="w-full">
                  <label class="label" for="first-name">First name</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="First name"
                    id="first-name"
                    name="first-name"
                    [(ngModel)]="paymentDetails.firstName"
                    [readOnly]="patron" />
                </div>
                <div class="w-full" [ngClass]="isMobile ? 'my-3' : 'ml-6'">
                  <label class="label" for="last-name">Last name</label>
                  <input
                    type="text"
                    class="input"
                    placeholder="Last name"
                    id="last-name"
                    name="last-name"
                    [(ngModel)]="paymentDetails.lastName"
                    [readOnly]="patron" />
                </div>
              </div>
              @if (configuration.custom.paymentPage.captureAddress) {
                <!-- Address and Postal Code-->
                <div [ngClass]="isMobile ? '' : 'mb-4 flex'">
                  <div class="w-full">
                    <label class="label" for="first-name">Address</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Line 1"
                      id="address"
                      name="address"
                      [(ngModel)]="paymentDetails.address.address1"
                      [readOnly]="patron" />
                  </div>
                  <div class="w-full" [ngClass]="isMobile ? 'my-3' : 'ml-6'">
                    <label class="label" for="last-name">Postal Code</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="Postal Code"
                      id="postalCode"
                      name="postalCode"
                      [(ngModel)]="paymentDetails.address.postalCode"
                      [readOnly]="patron" />
                  </div>
                </div>
                <!-- CITY, SUB AND COUNTRY-->
                <div [ngClass]="isMobile ? '' : 'mb-4 flex'">
                  <div class="w-full">
                    <label class="label" for="Country">Country</label>
                    <select
                      type="text"
                      [disabled]="patron != null"
                      class="input bg-white"
                      (ngModelChange)="onChangeCountry($event)"
                      id="countryCode"
                      name="countryCode"
                      [(ngModel)]="paymentDetails.address.countryCode">
                      <option
                        [value]="item.icaoCode"
                        *ngFor="let item of countryList">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full" [ngClass]="isMobile ? 'my-3' : 'ml-6'">
                    <label class="label" for="last-name"
                      >Sub Country Code</label
                    >
                    <select
                      type="text"
                      class="input bg-white"
                      [disabled]="patron != null"
                      id="SubCountryCode"
                      name="SubCountryCode"
                      [(ngModel)]="paymentDetails.address.subCountryCode">
                      <option
                        [value]="item.sub_country_code"
                        *ngFor="let item of subCountryList">
                        {{ item.sub_country_name }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full" [ngClass]="isMobile ? 'my-3' : 'ml-6'">
                    <label class="label" for="city">City</label>
                    <input
                      type="text"
                      class="input"
                      [placeholder]="this.configuration.city"
                      id="city"
                      name="city"
                      [(ngModel)]="paymentDetails.address.city"
                      [readOnly]="patron" />
                  </div>
                </div>
              }
              <div [ngClass]="isMobile ? 'mb-3' : 'mb-4'">
                <div class="w-full">
                  <label class="label" for="email">Email</label>
                  <input
                    type="email"
                    class="input"
                    placeholder="example@gmail.com"
                    id="email"
                    name="email"
                    [(ngModel)]="paymentDetails.email"
                    email
                    [readOnly]="patron" />
                </div>
                <p class="mt-1">
                  *Tickets will be delivered to this email in the MLB Ballpark
                  App.
                  <button
                    class="btn-icon w-4 h-4 text-white inline-flex bg-main rounded-full"
                    (click)="infoEmailModal()">
                    i
                  </button>
                </p>
              </div>
              @if (configuration.custom.paymentPage.capturePhone) {
                <div [ngClass]="isMobile ? '' : 'mb-4 flex'">
                  <div class="w-1/3">
                    <label class="label" for="Country">Country Code</label>
                    <select
                      type="text"
                      [disabled]="patron != null"
                      class="input bg-white"
                      (ngModelChange)="onChangeCountry($event)"
                      id="countryCode"
                      name="countryCode"
                      [(ngModel)]="paymentDetails.phone.countryCode">
                      <option
                        [value]="item.icaoCode"
                        *ngFor="let item of countryList">
                        +{{ item.phoneCode }} ({{ item.icaoCode }})
                      </option>
                    </select>
                  </div>
                  <div class="w-full" [ngClass]="isMobile ? 'my-3' : 'ml-6'">
                    <label class="label" for="city">Phone</label>
                    <input
                      type="text"
                      class="input"
                      placeholder="XXXXXXXX"
                      id="phone"
                      name="phone"
                      [(ngModel)]="paymentDetails.phone.phone"
                      [readOnly]="patron" />
                  </div>
                </div>
              }
            </div>

            <div
              id="dropin-container"
              [class.hidden]="paymentDetails.digitalWallet !== null"></div>
            <button
              id="braintree-button"
              class="btn-main bg-main-light ml-auto"
              [class.hidden]="paymentDetails.digitalWallet !== null">
              Use this card
            </button>
            <div></div>
          </div>
        </div>
      </form>
    </div>

    <div
      class="mt-7 flex"
      [ngClass]="isMobile ? 'justify-center' : 'justify-end'">
      <button class="btn-black disabled:bg-gray-600" (click)="checkout()">
        Checkout
        <span class="ml-4"
          ><img src="/assets/img/arrow-right-white-icon.svg" alt=""
        /></span>
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="confirmationPage"
  id="confirmation"
  class="h-full flex justify-center items-center bg-success-off-white"
  [ngClass]="isMobile ? 'px-8 py-10' : 'px-20 py-14'">
  <div
    class="box-white py-12 text-center"
    [ngClass]="isMobile ? 'w-full h-auto px-14' : 'w-128 h-72 px-20'">
    <img
      class="m-auto"
      src="/assets/img/checkmark-green-icon.svg"
      alt="Green checkmark icon" />
    <h2 class="my-4">Order Placed!</h2>
    <h4 class="font-normal text-gray-500">
      Your order was placed successfully.
    </h4>
  </div>
</div>
