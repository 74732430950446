<div id="checkout" class="h-full bg-gray-100 overflow-y-auto">
  <div
    [ngClass]="{
      'w-full px-5 py-7': isMobile,
      'max-w-max px-20 py-14 mx-auto': !isMobile,
    }"
    [class.flex]="!isRainout"
    [class.flex-col]="!isRainout">
    <div [ngClass]="isMobile ? 'mb-7 text-center' : 'mb-3'">
      <h1 class="text-4xl" [ngClass]="{ 'mb-1': isMobile }">Your cart</h1>
      <app-timer
        (transactionExpired)="onTransactionExpired()"
        [createdTime]="this.transaction?.transaction.created_at"></app-timer>
    </div>

    @if (isRainout || isMobile) {
      <div class="flex flex-col gap-y-4">
        <div class="flex" [ngClass]="{ 'flex-wrap': isMobile }">
          <!-- Cart -->
          <div
            id="ticket-details"
            class="box-white h-auto w-full"
            [ngClass]="
              isMobile
                ? 'mb-5'
                : 'box-responsive w-1/2 flex flex-col justify-between'
            ">
            <div>
              <div [ngClass]="isMobile ? 'box-header-center' : 'box-header'">
                <div [ngClass]="{ 'w-full text-center': isMobile }">
                  <h3 class="mb-1 font-bold">Your Ticket Details</h3>
                  <p class="font-semibold text-gray-400">
                    {{ transaction?.transaction.event.description }} -
                    {{ transaction?.transaction.event.date | date: 'short' }}
                  </p>
                </div>
                <!-- <button class="btn-main-dark ml-5">Delivery method</button> -->
              </div>
              <div class="box-body" [ngClass]="isMobile ? '' : 'm-2'">
                <h4
                  *ngFor="let seat of seatsByPrice"
                  class="font-bold text-main-dark"
                  [ngClass]="{ 'text-center': isMobile }">
                  <span class="underline">{{ seat.quantity }} Seats</span> x
                  <span class="underline">{{
                    transaction.transaction.buyerTypeName
                  }}</span>
                  at
                  <span class="text-red"
                    >${{
                      seat.price + upgradeSelected?.price || 0 | number: '1.2-2'
                    }}</span
                  >
                </h4>
                <div class="w-full" [ngClass]="isMobile ? '' : 'px-4'">
                  <table class="w-full table-fixed border-separate">
                    <tr
                      *ngFor="let section of seatsForTable"
                      [ngClass]="{ 'mb-3 flex flex-wrap': isMobile }">
                      <td
                        class="td"
                        [ngClass]="isMobile ? 'w-full mb-1' : 'w-16'">
                        <div class="flex justify-center items-center">
                          <img
                            src="/assets/img/chair-blue-icon.svg"
                            alt="main chair icon"
                            class="mr-1" />
                          <p class="font-bold text-center text-main">
                            {{ section.totalSectionSeats }}
                          </p>
                        </div>
                      </td>
                      <td
                        class="td flex"
                        [ngClass]="isMobile ? 'w-full mb-1' : 'max-w-[30px]'">
                        <p class="flex text-center items-center">
                          Section
                          <span class="ml-2 font-bold text-main">{{
                            section.id
                          }}</span>
                        </p>
                      </td>
                      <td class="p-0" [ngClass]="isMobile ? 'w-full' : ''">
                        <div
                          *ngFor="let row of section.rows"
                          class="td-lines flex">
                          <p class="mr-10">
                            Row<span class="ml-2 font-bold text-main">{{
                              row.id
                            }}</span>
                          </p>
                          <p>
                            Seats<span class="ml-2 font-bold text-main">{{
                              row.seatsRanges.join(', ')
                            }}</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div [ngClass]="isMobile ? 'box-footer-center' : 'box-footer'">
              <h5 class="font-bold">
                Price: ${{
                  transaction?.transaction.tdc_transaction.totalPriceSeats
                    | number: '1.2-2'
                }}
              </h5>
            </div>
          </div>

          @if (isUpgrade) {
            <!-- Group outing -->
            <div
              id="group-outing"
              class="box-white h-auto w-full"
              [ngClass]="isMobile ? '' : 'box-responsive w-1/2 ml-10'">
              <div [ngClass]="isMobile ? 'box-header-center' : 'box-header'">
                <h3 class="font-bold">Enhance your group outing</h3>
              </div>
              <div class="box-body flex flex-wrap">
                <!-- Upgrades -->
                <div
                  *ngFor="
                    let upgrade of transaction?.transaction.event.upgrades
                  "
                  [ngClass]="isMobile ? 'w-full' : ''">
                  <!-- <label for="{{ upgrade.id }}" class="package-card bg-center bg-cover bg-no-repeat bg-opacity-10" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" [ngStyle]="{'background-image': 'url('+upgrade.url_image+')'}"> -->
                  <label
                    for="{{ upgrade.id }}"
                    class="package-card relative overflow-hidden"
                    [ngClass]="{
                      checked:
                        upgradeSelected !== null &&
                        upgrade.id === upgradeSelected.id,
                    }">
                    <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="deselectUpgrade(upgrade.id)"> -->
                    <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="toggleUpgrade(upgrade.id)"> -->
                    <div class="w-full h-full absolute top-0 left-0 opacity-10">
                      <img
                        [src]="upgrade.url_image"
                        class="w-full h-full object-center object-cover"
                        alt="" />
                    </div>
                    <div class="package-radio">
                      <!-- <input type="radio" class="btn-radio" name="package" id="{{ upgrade.id }}"
                      [checked]="upgradeSelected != null && upgrade.id === upgradeSelected.id"> -->
                      <input
                        type="checkbox"
                        class="btn-radio"
                        #radioButton
                        name="{{ upgrade.id }}"
                        id="{{ upgrade.id }}"
                        [value]="upgrade.buyerTypeId"
                        (change)="onChangeUpgrade($event, radioButton)"
                        [checked]="
                          upgradeSelected !== null &&
                          upgrade.id === upgradeSelected.id
                        " />
                    </div>
                    <div class="w-full">
                      <div class="package-header">
                        <h5 class="mr-2 font-bold">{{ upgrade.name }}</h5>
                        <div>
                          <p class="min-w-max font-bold">
                            + ${{ upgrade.price | number: '1.2-2' }}
                          </p>
                          <h6 class="font-bold text-right">Per ticket</h6>
                          <!-- asumimos que es por ticket me suena que lo ha dicho el cliente -->
                          <!-- <h6 class="font-bold text-right">{{ package.priceType === 'ticket'? 'Per ticket' : 'Total' }}</h6> -->
                        </div>
                      </div>
                      <div class="package-body">
                        <p>{{ upgrade.description }}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                *ngIf="upgradeSelected"
                [ngClass]="isMobile ? 'box-footer-center' : 'box-footer'">
                <h5 class="font-bold text-main">
                  Extra Cost (${{ upgradeSelected.price | number: '1.2-2' }} x
                  {{ totalTransactionSeats }} Seats): ${{
                    upgradeSelected.price * totalTransactionSeats
                      | number: '1.2-2'
                  }}
                </h5>
              </div>
            </div>
          }
        </div>

        <!-- <div class="px-6 py-4 relative bg-main-dark rounded-2.5xl"> -->
        <div
          class="flex justify-center"
          [ngClass]="{ 'flex-wrap': isMobile, 'mt-auto': !isUpgrade }">
          @if (isRainout) {
            <!-- RAIN OUT -->
            <div
              class="box-main"
              [ngClass]="isMobile ? 'mb-5' : 'box-responsive w-1/2 h-48 flex'">
              <div
                class="px-6 py-4 bg-rain bg-cover flex items-center rounded-2.5xl"
                [ngClass]="isMobile ? 'justify-center' : 'w-1/3'">
                <h3
                  class="font-bold flex"
                  [ngClass]="{ 'text-base': isMobile }">
                  <span class="mr-1 text-red">*</span> Protect your group outing
                </h3>
              </div>
              <!-- Rainout Choose -->
              <div
                id="rainout-insurance"
                *ngIf="!showRainoutInfo"
                class="px-6 py-4 flex"
                [ngClass]="
                  isMobile ? 'flex-wrap justify-center' : 'w-2/3 items-center'
                ">
                <div [ngClass]="isMobile ? 'mx-8 mb-4 text-center' : 'mr-4'">
                  <h4
                    *ngIf="eventDate - nowDate > deadlineInMilliseconds"
                    class="mb-2 font-normal"
                    [ngClass]="{ 'text-sm': isMobile }">
                    Would you like to add Rainout Insurance to protect your
                    outing?
                  </h4>
                  <div
                    *ngIf="
                      eventDate - nowDate > deadlineInMilliseconds;
                      else deadlineReached
                    "
                    class="flex items-center"
                    [ngClass]="{ 'justify-center': isMobile }">
                    <input
                      type="radio"
                      name="rain-out"
                      id="yes"
                      [value]="true"
                      [(ngModel)]="
                        transaction?.transaction?.tdc_transaction
                          .hasInsuranceRainOut
                      "
                      (ngModelChange)="onRainoutChange($event)" />
                    <label for="yes" class="ml-2 mr-6">Yes</label>
                    <input
                      type="radio"
                      name="rain-out"
                      id="no"
                      [value]="false"
                      [(ngModel)]="
                        transaction?.transaction?.tdc_transaction
                          .hasInsuranceRainOut
                      "
                      (ngModelChange)="onRainoutChange($event)" />
                    <label for="no" class="ml-2">No</label>
                  </div>
                  <ng-template #deadlineReached>
                    <h5 class="font-normal text-gray-400">
                      Rainout Insurance is only available 21+ days before the
                      game.
                    </h5>
                  </ng-template>
                </div>
                <button
                  class="btn-main w-20 my-2 bg-main-dark border border-white"
                  (click)="showRainoutInfo = true">
                  +Info
                </button>
              </div>
              <!-- Rainout Info -->
              <div
                *ngIf="showRainoutInfo"
                class="px-6 py-4 flex"
                [ngClass]="
                  isMobile ? 'flex-wrap justify-center' : 'w-2/3 items-center'
                ">
                <div
                  [ngClass]="isMobile ? 'mb-4' : 'h-full mr-4 overflow-y-auto'">
                  <p class="font-bold mb-1">About</p>
                  <p>
                    The
                    {{ configurationService.configuration.clientName }} offer
                    group ticket buyers the opportunity to purchase Group
                    Rainout Insurance (ROI) to protect against the
                    weather-related postponements of games by the
                    {{ configurationService.configuration.clientName }} or Major
                    League Baseball. In the event your group ticket game is
                    postponed due to weather-related reasons, you will be
                    eligible for a full-refund (less the cost of ROI). Rainout
                    Insurance may be purchased for 10 percent of the total
                    ticket package price. For more information on Group Rainout
                    Insurance please
                    <a
                      class="underline text-main-light"
                      [href]="
                        configurationService.configuration.rainoutInsuranceUrl
                      "
                      target="_blank"
                      >click here</a
                    >.
                  </p>
                </div>
                <button
                  class="btn-white w-20 my-2 text-main-dark border border-main-dark"
                  (click)="showRainoutInfo = false">
                  Close
                </button>
              </div>
            </div>
          }

          <!-- SUMMARY -->
          <div
            id="cart-summary"
            class="box-white w-full px-6 py-4"
            [ngClass]="
              isMobile ? '' : 'box-responsive box-summary flex w-1/2 ml-10'
            ">
            <div
              class="m-auto border-gray-200"
              [ngClass]="isMobile ? 'pb-3 border-b' : 'w-2/3 pr-3 border-r'">
              <div class="m-auto overflow-y-auto">
                <h5
                  class="mb-2 font-bold"
                  [ngClass]="isMobile ? 'text-center' : 'text-right'">
                  Cart Summary
                </h5>
                <div class="text-gray-500">
                  <div
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 [ngClass]="isMobile ? 'text-left' : 'text-right'">
                      Seats:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction.totalPriceSeats
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5
                      [ngClass]="isMobile ? 'text-left' : 'text-right'"
                      class="font-normal">
                      Total ticket fees:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction.serviceCharges
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="
                      transaction?.transaction.tdc_transaction.amusementTax > 0
                    "
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 class="font-normal text-right">
                      Amusement tax (12% of seat cost):
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction.amusementTax
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="rainoutValue"
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 class="font-normal text-right">Rainout Insurance:</h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction
                          .insueranceRainOutPrice | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    class="overflow-y-auto h-20 mb-2"
                    *ngIf="acquiredUpsells">
                    <div
                      *ngFor="let upsell of acquiredUpsells"
                      [ngClass]="
                        isMobile ? 'mb-2 text-center' : 'sum-list-item'
                      ">
                      <h5 class="text-right">
                        <span class="mr-1 font-normal">By purchasing</span
                        >{{ upsell.goal }} tickets
                        <span class="mx-1 font-normal inline-block"
                          >you receive:</span
                        >{{ upsell.name }}.
                      </h5>
                      <div class="min-w-max">
                        <h5
                          class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                          [ngClass]="
                            isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'
                          ">
                          BENEFIT
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [ngClass]="isMobile ? 'pt-3' : 'w-1/3 pl-3 m-auto'">
              <div class="text-center">
                <h4 class="font-bold">Ticket Total:</h4>
                <h4 class="font-bold">
                  ${{
                    transaction?.transaction.tdc_transaction.balance
                      | number: '1.2-2'
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    } @else {
      <!--**** IF NO RAINOUT -->
      <div class="flex flex-row justify-between gap-y-4">
        <div class="flex" [ngClass]="{ 'flex-wrap': isMobile }">
          <!-- Cart -->
          <div
            id="ticket-details"
            class="box-white h-auto w-full"
            [ngClass]="
              isMobile
                ? 'mb-5'
                : 'box-responsive w-1/2 flex flex-col justify-between'
            ">
            <div>
              <div [ngClass]="isMobile ? 'box-header-center' : 'box-header'">
                <div [ngClass]="{ 'w-full text-center': isMobile }">
                  <h3 class="mb-1 font-bold">Your Ticket Details</h3>
                  <p class="font-semibold text-gray-400">
                    {{ transaction?.transaction.event.description }} -
                    {{ transaction?.transaction.event.date | date: 'short' }}
                  </p>
                </div>
                <!-- <button class="btn-main-dark ml-5">Delivery method</button> -->
              </div>
              <div class="box-body" [ngClass]="isMobile ? '' : 'm-2'">
                <h4
                  *ngFor="let seat of seatsByPrice"
                  class="font-bold text-main-dark"
                  [ngClass]="{ 'text-center': isMobile }">
                  <span class="underline">{{ seat.quantity }} Seats</span> x
                  <span class="underline">{{
                    transaction.transaction.buyerTypeName
                  }}</span>
                  at
                  <span class="text-red"
                    >${{
                      seat.price + upgradeSelected?.price || 0 | number: '1.2-2'
                    }}</span
                  >
                </h4>
                <div class="w-full" [ngClass]="isMobile ? '' : 'px-4'">
                  <table class="w-full table-fixed border-separate">
                    <tr
                      *ngFor="let section of seatsForTable"
                      [ngClass]="{ 'mb-3 flex flex-wrap': isMobile }">
                      <td
                        class="td"
                        [ngClass]="isMobile ? 'w-full mb-1' : 'w-16'">
                        <div class="flex justify-center items-center">
                          <img
                            src="/assets/img/chair-blue-icon.svg"
                            alt="main chair icon"
                            class="mr-1" />
                          <p class="font-bold text-center text-main">
                            {{ section.totalSectionSeats }}
                          </p>
                        </div>
                      </td>
                      <td
                        class="td flex"
                        [ngClass]="isMobile ? 'w-full mb-1' : 'max-w-[30px]'">
                        <p class="flex text-center items-center">
                          Section
                          <span class="ml-2 font-bold text-main">{{
                            section.id
                          }}</span>
                        </p>
                      </td>
                      <td class="p-0" [ngClass]="isMobile ? 'w-full' : ''">
                        <div
                          *ngFor="let row of section.rows"
                          class="td-lines flex">
                          <p class="mr-10">
                            Row<span class="ml-2 font-bold text-main">{{
                              row.id
                            }}</span>
                          </p>
                          <p>
                            Seats<span class="ml-2 font-bold text-main">{{
                              row.seatsRanges.join(', ')
                            }}</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div [ngClass]="isMobile ? 'box-footer-center' : 'box-footer'">
              <h5 class="font-bold">
                Price: ${{
                  transaction?.transaction.tdc_transaction.totalPriceSeats
                    | number: '1.2-2'
                }}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col justify-between gap-y-5"
          [ngClass]="{ 'flex-wrap': isMobile, 'mt-auto': !isUpgrade }">
          @if (isUpgrade) {
            <!-- Group outing -->
            <div
              id="group-outing"
              class="box-white h-full"
              [ngClass]="isMobile ? '' : 'box-responsive w-1/2 ml-10'">
              <div [ngClass]="isMobile ? 'box-header-center' : 'box-header'">
                <h3 class="font-bold h-full">Enhance your group outing</h3>
              </div>
              <div class="box-body flex flex-wrap">
                <!-- Upgrades -->
                <div
                  *ngFor="
                    let upgrade of transaction?.transaction.event.upgrades
                  "
                  [ngClass]="isMobile ? 'w-full' : ''">
                  <!-- <label for="{{ upgrade.id }}" class="package-card bg-center bg-cover bg-no-repeat bg-opacity-10" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" [ngStyle]="{'background-image': 'url('+upgrade.url_image+')'}"> -->
                  <label
                    for="{{ upgrade.id }}"
                    class="package-card relative overflow-hidden"
                    [ngClass]="{
                      checked:
                        upgradeSelected !== null &&
                        upgrade.id === upgradeSelected.id,
                    }">
                    <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="deselectUpgrade(upgrade.id)"> -->
                    <!-- <label for="{{ upgrade.id }}" class="package-card relative overflow-hidden" [ngClass]="{'checked': upgradeSelected != null && upgrade.id === upgradeSelected.id}" (click)="toggleUpgrade(upgrade.id)"> -->
                    <div class="w-full h-full absolute top-0 left-0 opacity-10">
                      <img
                        [src]="upgrade.url_image"
                        class="w-full h-full object-center object-cover"
                        alt="" />
                    </div>
                    <div class="package-radio">
                      <!-- <input type="radio" class="btn-radio" name="package" id="{{ upgrade.id }}"
                                  [checked]="upgradeSelected != null && upgrade.id === upgradeSelected.id"> -->
                      <input
                        type="checkbox"
                        class="btn-radio"
                        #radioButton
                        name="{{ upgrade.id }}"
                        id="{{ upgrade.id }}"
                        [value]="upgrade.buyerTypeId"
                        (change)="onChangeUpgrade($event, radioButton)"
                        [checked]="
                          upgradeSelected !== null &&
                          upgrade.id === upgradeSelected.id
                        " />
                    </div>
                    <div class="w-full">
                      <div class="package-header">
                        <h5 class="mr-2 font-bold">{{ upgrade.name }}</h5>
                        <div>
                          <p class="min-w-max font-bold">
                            + ${{ upgrade.price | number: '1.2-2' }}
                          </p>
                          <h6 class="font-bold text-right">Per ticket</h6>
                          <!-- asumimos que es por ticket me suena que lo ha dicho el cliente -->
                          <!-- <h6 class="font-bold text-right">{{ package.priceType === 'ticket'? 'Per ticket' : 'Total' }}</h6> -->
                        </div>
                      </div>
                      <div class="package-body">
                        <p>{{ upgrade.description }}</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                *ngIf="upgradeSelected"
                [ngClass]="isMobile ? 'box-footer-center' : 'box-footer'">
                <h5 class="font-bold text-main">
                  Extra Cost (${{ upgradeSelected.price | number: '1.2-2' }} x
                  {{ totalTransactionSeats }} Seats): ${{
                    upgradeSelected.price * totalTransactionSeats
                      | number: '1.2-2'
                  }}
                </h5>
              </div>
            </div>
          }

          <!-- SUMMARY -->
          <div
            id="cart-summary"
            class="box-white w-full px-6 py-4"
            [ngClass]="
              isMobile ? '' : 'box-responsive box-summary flex w-1/2 ml-10'
            ">
            <div
              class="m-auto border-gray-200"
              [ngClass]="isMobile ? 'pb-3 border-b' : 'w-2/3 pr-3 border-r'">
              <div class="m-auto overflow-y-auto">
                <h5
                  class="mb-2 font-bold"
                  [ngClass]="isMobile ? 'text-center' : 'text-right'">
                  Cart Summary
                </h5>
                <div class="text-gray-500">
                  <div
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 [ngClass]="isMobile ? 'text-left' : 'text-right'">
                      Seats:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction.totalPriceSeats
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5
                      [ngClass]="isMobile ? 'text-left' : 'text-right'"
                      class="font-normal">
                      Total ticket fees:
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction.serviceCharges
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="
                      getEnvironment() &&
                      transaction?.transaction?.tdc_transaction.amusementTax > 0
                    "
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 class="font-normal text-right">
                      Amusement tax (12% of seat cost):
                    </h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction.amusementTax
                          | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    *ngIf="rainoutValue"
                    [ngClass]="isMobile ? 'mb-2 text-center' : 'sum-list-item'">
                    <h5 class="font-normal text-right">Rainout Insurance:</h5>
                    <h5
                      class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                      [ngClass]="isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'">
                      ${{
                        transaction?.transaction.tdc_transaction
                          .insueranceRainOutPrice | number: '1.2-2'
                      }}
                    </h5>
                  </div>
                  <div
                    class="overflow-y-auto h-20 mb-2"
                    *ngIf="acquiredUpsells">
                    <div
                      *ngFor="let upsell of acquiredUpsells"
                      [ngClass]="
                        isMobile ? 'mb-2 text-center' : 'sum-list-item'
                      ">
                      <h5 class="text-right">
                        <span class="mr-1 font-normal">By purchasing</span
                        >{{ upsell.goal }} tickets
                        <span class="mx-1 font-normal inline-block"
                          >you receive:</span
                        >{{ upsell.name }}.
                      </h5>
                      <div class="min-w-max">
                        <h5
                          class="w-24 px-2 py-1 bg-main-off-white rounded-lg"
                          [ngClass]="
                            isMobile ? 'mx-auto mt-1' : 'ml-4 text-right'
                          ">
                          BENEFIT
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [ngClass]="isMobile ? 'pt-3' : 'w-1/3 pl-3 m-auto'">
              <div class="text-center">
                <h4 class="font-bold">Ticket Total:</h4>
                <h4 class="font-bold">
                  ${{
                    transaction?.transaction.tdc_transaction.balance
                      | number: '1.2-2'
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    <div
      class="mt-7 flex"
      [ngClass]="
        isMobile ? 'flex-col-reverse items-center' : 'justify-between'
      ">
      <!-- <button class="btn-black disabled:bg-gray-600" (click)="goTo('payment')" [disabled]="!isRainOutAnswred"> -->
      <button class="btn-main-reverse h-12" (click)="restartTransaction()">
        Restart
      </button>
      <button
        class="btn-black bg-red disabled:bg-gray-600"
        [ngClass]="{ 'mb-5': isMobile }"
        (click)="proceedToPayment()"
        [disabled]="
          configurationService.configuration.rainout &&
          transaction?.transaction?.tdc_transaction.hasInsuranceRainOut ===
            null &&
          eventDate - nowDate > deadlineInMilliseconds
        "
        [tooltip]="
          configurationService.configuration.rainout &&
          transaction?.transaction?.tdc_transaction.hasInsuranceRainOut ===
            null &&
          eventDate - nowDate > deadlineInMilliseconds
            ? 'Please select a Rainout Insurance option.'
            : ''
        "
        placement="top"
        triggers="pointerenter:pointerout">
        Proceed to Payment
        <span class="ml-4 pointer-events-none"
          ><img src="/assets/img/arrow-right-white-icon.svg" alt=""
        /></span>
      </button>
    </div>
  </div>
</div>
