import { Component, inject, input, TemplateRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SeatManagementService } from 'src/app/digital-venue/services/seat-management.service';
import { AvailabilityService, UtilitiesService } from 'src/app/shared/services';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { DvmService } from 'src/app/digital-venue/services/dvm.service';
import { Seat, SeatsBySection } from 'src/app/shared/models';
import { ModalsService } from 'src/app/shared/modals/modals.service';

@Component({
  selector: 'app-cart-body',
  standalone: true,
  imports: [BrowserModule],
  templateUrl: './cart-body.component.html',
  styleUrl: './cart-body.component.scss',
})
export class CartBodyComponent {
  sectionsListOpen: string[] = [];
  isTicketFeeEnabled = input(false);
  seatFeeTemplate = input<TemplateRef<any>>();
  sectionFeeTemplate = input<TemplateRef<any>>();
  sectionPriceTemplate = input<TemplateRef<any>>();
  seats = input<Seat[]>();
  seatsBySection = input<SeatsBySection[]>();
  availabilityService = inject(AvailabilityService);
  dvmService = inject(DvmService);
  modalService = inject(ModalsService);
  seatManagementService = inject(SeatManagementService);
  tutorialService = inject(TutorialService);
  utilitiesService = inject(UtilitiesService);

  get isMobile() {
    return this.utilitiesService.isMobile;
  }

  removeSectionSeatsFromSelectedSeats(section) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.unselectSelectedSeats(
      Object.values(section.seats).map((v: Seat) => {
        return { id: v.id, original_id: v.original_id };
      })
    );
  }

  removeOneFromSelectedSeats(seat: Seat) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    this.seatManagementService.unselectSelectedSeats([
      { id: seat.id, original_id: seat.original_id },
    ]);
  }

  goToSeatmap(sectionId) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    // console.log('Section map ID: ', sectionId);
    this.dvmService.loadMap(sectionId);
  }

  isAda(seatId) {
    if (seatId && typeof seatId === 'string') {
      const sectionId = seatId.split('-')[0];
      const adaSeats = Object.values(
        this.availabilityService.seatsAvailability[sectionId]
      )
        .filter(a => a.isAda)
        .map(a => a.id);
      return adaSeats.includes(seatId);
    }
    return false;
  }

  toggleSectionList(sectionId: string, element?: HTMLElement) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    if (this.sectionsListOpen.includes(sectionId)) {
      this.sectionsListOpen = this.sectionsListOpen.filter(
        item => item !== sectionId
      );
      console.log('Remove List Open: ', this.sectionsListOpen);
      if (element) {
        element.classList.replace('rotate', 'no-rotate');
      }
    } else {
      this.sectionsListOpen.push(sectionId);
      console.log('Add List Open: ', this.sectionsListOpen);
      if (element) {
        element.classList.replace('no-rotate', 'rotate');
      }
    }
  }

  load3dView(seatId) {
    if (this.tutorialService.isOpen()) {
      return;
    }

    let panoId = seatId;
    if (seatId.includes('S_Bleacher') || seatId.includes('S_BLCHADA')) {
      panoId = 'S_Bleacher';
    }
    if (
      this.dvmService.viewer3d &&
      this.dvmService.viewer3d.getViewId() === panoId
    ) {
      return;
    }
    this.dvmService.load3dView(panoId);
  }

  isGASection(sectionId) {
    return sectionId == 'S_BLCHADA' || sectionId === 'S_Bleacher';
  }

  /**
   * Open seat definitions
   * @param {string} id
   * @version 1.3.1
   * @since 1.3.1
   */
  openSeatDefinitionModal(id: string): void {
    const seatInfo = this.availabilityService.getSeatByIdFromAvailability(id);
    const dvmSeat = this.dvmService.viewer.getNodeById(id);
    this.modalService.openSeatDefinitionModal(seatInfo, dvmSeat.original_id);
  }

  /**
   * Returns boolean to know if seat has definitions or not, in this moments only is used on template
   * @param {string} id
   * @version 1.3.1
   * @since 1.3.1
   */
  hasSeatDefinition(id: string): boolean {
    const seatInfo = this.availabilityService.getSeatByIdFromAvailability(id);
    return seatInfo.seatDefinition.length > 0;
  }

  trackByFn(index: number, section: any) {
    return section.original_id;
  }
}
