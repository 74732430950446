{
  "name": "group-sales-portal",
  "version": "1.9.0",
  "scripts": {
    "build-dev": "ng build --configuration development --aot --output-hashing=all",
    "build-pre": "ng build --configuration preproduction --aot --output-hashing=all",
    "build-pro": "ng build --configuration production --aot --output-hashing=all",
    "build": "ng build",
    "lint": "ng lint",
    "ng": "ng",
    "start:local": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration local",
    "start:local:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration local --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key'",
    "start:local:chicagocubs": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:chicagocubs:ssl": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:chicagocubsspring": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:chicagocubsspring:ssl": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:miamimarlins": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:miamimarlins:ssl": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:pittsburghpirates": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:pittsburghpirates:ssl": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:redsox:ssl": "ng serve --host local.redsox.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:washingtonnationals": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:washingtonnationals:ssl": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:local:houstonastros": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --configuration local",
    "start:local:houstonastros:ssl": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration local",
    "start:dev:chicagocubs": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:chicagocubsspring": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:houstonastros": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:pittsburghpirates": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:dev:washingtonnationals": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --configuration development",
    "start:pre:astros": "ng serve --host local.houstonastros.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:pre:chicagocubs": "ng serve --host local.chicagocubs.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:chicagocubsspring": "ng serve --host local.chicagocubsspring.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:houstonastros": "ng serve --host local.houstonastros.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:miamimarlins": "ng serve --host local.miamimarlins.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:pittsburghpirates": "ng serve --host local.pittsburghpirates.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre:redsox": "ng serve --host local.redsox.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:pre:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' --configuration preproduction",
    "start:pre:washingtonnationals": "ng serve --host local.washingtonnationals.3ddigitalvenue.com --open --port 4200 --configuration preproduction",
    "start:pre": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration preproduction",
    "start:prod": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration production",
    "start:ssl": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --ssl true --ssl-cert 'ssl/server.crt' --ssl-key 'ssl/server.key' ",
    "start": "ng serve --host local.3ddigitalvenue.com --port 4200 --open --configuration development",
    "test": "ng test",
    "watch": "ng build --watch --configuration development"
  },
  "private": true,
  "dependencies": {
    "@3ddv/dvm-internal": "^2.6.1",
    "@angular/animations": "^17.3.11",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@okta/okta-angular": "^6.3.2",
    "@okta/okta-auth-js": "^7.5.0",
    "@popperjs/core": "^2.11.8",
    "driver.js": "^1.3.1",
    "luxon": "^3.5.0",
    "ngx-bootstrap": "~12.0.0",
    "ngx-device-detector": "~7.0.0",
    "ngx-slider-v2": "~17.0.0",
    "npn-slider": "^1.3.2",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@types/braintree-web-drop-in": "^1.39.3",
    "@types/jasmine": "~5.1.4",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.11.5",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "autoprefixer": "^10.4.17",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "~8.4.18",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "rimraf": "~5.0.5",
    "tailwindcss": "~2.2.19",
    "typescript": "~5.3.3",
    "zod": "^3.22.4"
  }
}
