import { MapViewer } from '@3ddv/dvm-internal';
import { Injectable } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import {
  AvailabilityService,
  CartService,
  UtilitiesService,
} from 'src/app/shared/services';
import { TutorialService } from 'src/app/shared/services/tutorial.service';
import { Seat } from '../../shared/models';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DvmService } from './dvm.service';
import { IsolatedSeatsService } from './isolated-seats.service';

export type CombinedIds = {
  original_id: string;
  id: string;
};

@Injectable({
  providedIn: 'root',
})
export class SeatManagementService {
  viewer: MapViewer;
  needADA: boolean = false;

  constructor(
    private dvmService: DvmService,
    private cartService: CartService,
    private utilitiesService: UtilitiesService,
    private availabilityService: AvailabilityService,
    private isolatedSeatsService: IsolatedSeatsService,
    private configurationService: ConfigurationService,
    private modalsService: ModalsService,
    private tutorialService: TutorialService
  ) {
    const viewerSubscription = this.dvmService.viewerSubject.subscribe({
      next: response => (this.viewer = response),
      error: error => {
        console.error(error);
        const modalData = {
          title: 'ERROR',
          content: 'An Error occurred while trying to get the Viewer.',
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        this.modalsService.openModal(modalData);
      },
    });

    const tutorial$ = this.tutorialService.tutorial$.subscribe(
      tutorialConfig => {
        if (tutorialConfig.activeStepCommand === 'setSeatsInCart') {
          const selectedSeatNodes =
            this.tutorialService.getTutorialSelectedSeatNodes();

          this.selectAvailableSeats(
            selectedSeatNodes.map(node => ({
              id: node.id,
              original_id: node.original_id,
            }))
          );
        }

        if (tutorialConfig.isTutorialEnded) {
          this.unselectAllSelectedSeats();
        }
      }
    );
    this.dvmService.viewerSubscriptions.push(viewerSubscription, tutorial$);
  }

  selectAvailableSeats(nodeIds: CombinedIds[]) {
    // console.log('Node Seats: ', nodeIds);

    // Reset selectedSeats if you are adding a group of seats (best available or area selection)
    if (nodeIds.length > 1) {
      this.dvmService.viewer.unselectAll();
      this.cartService.selectedSeats = {};
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
      // this.cartService.selectedSections = [];
    }

    // Select Seat(s) on Viewer
    let success = this.dvmService.viewer.select(nodeIds);
    // console.log('Success SELECT Seat(s)? ', success);

    this.selectSeatsForCart(nodeIds);
  }

  unselectSelectedSeats(nodeIds: any[]) {
    // console.log('Node Seats: ', nodeId);
    // let clickedSeat = this.cartService.selectedSeats[nodeIds[0]];
    // if (clickedSeat !== undefined) {
    // Unselect Seat on Viewer
    let success = this.dvmService.viewer.unselect(nodeIds);
    // console.log('Success UNSELECT Seat(s)? ', success);
    // }

    for (const nodeId of nodeIds) {
      // Remove one new seat (in Cart Service)
      let isDeleted = delete this.cartService.selectedSeats[nodeId.id];
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
      // console.log('isDeleted? ', isDeleted);
    }
    // console.log('Selected Seats (Removing): ', this.cartService.selectedSeats);

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;

    // Update cart Seats data
    this.updateCartSeatsBySection();

    if (
      this.dvmService.isSectionMap === true &&
      !this.cartService.selectedSeatsBySection[this.dvmService.seatmapId]
    ) {
      // Going back to Topview map
      // this.dvmService.loadMap();
    } else {
      // Update selected sections on Topview and MiniMap
      this.updateSelectedSectionsOnTopview();
    }
  }

  unselectAllSelectedSeats() {
    let success = this.dvmService.viewer.unselectAll();
    // console.log('Success UNSELECT ALL Seats? ', success);

    // Reset new seats (in Transaction Service)
    this.cartService.selectedSeats = {};
    this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    // console.log('Selected Seats (Removing All): ', this.cartService.selectedSeats);

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;

    // Update cart Seats data
    this.updateCartSeatsBySection();

    // Going back to Topview map
    this.dvmService.loadMap();
  }

  unselectAllSelectedSeatsWithoutLoadingMap() {
    const selectedSeats = this.dvmService.viewer.getNodes({
      type: ['seat'],
      state: 'selected',
    });
    this.dvmService.viewer.unselect(selectedSeats);
    // Reset new seats (in Transaction Service)
    this.cartService.selectedSeats = {};
    this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    // console.log('Selected Seats (Removing All): ', this.cartService.selectedSeats);

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;

    // Update cart Seats data
    this.updateCartSeatsBySection();
  }

  isStringOrCombined;

  selectSeatsForCart(nodeIds: CombinedIds[] | string[]) {
    // Add seats to Cart
    for (const node of nodeIds) {
      // Merging new seat info from two objects:
      let newSeat = this.utilitiesService.splittedSeatInfoFromId(node) as Seat;
      let id: string;
      if (typeof node === 'string') {
        id = node;
      } else {
        id = node.id;
      }

      const seatInfo = this.availabilityService.getSeatByIdFromAvailability(id);
      newSeat.price = seatInfo?.price?.[0]?.value; //The first price is the default buyer type price.
      newSeat.totalFee = seatInfo?.price?.[0]?.totalFee; //The first price is the default buyer type price.
      newSeat.totalPriceWithFees = seatInfo?.price?.[0]?.totalPriceWithFees; //The first price is the default buyer type price.
      // Add one new seat (in Cart Service)

      this.cartService.selectedSeats[newSeat.id] = newSeat;
      this.cartService.selectedSeats$.next(this.cartService.selectedSeats);
    }

    // Update cart Seats data
    this.updateCartSeatsBySection();

    const { totalFee, totalPrice, totalPriceWithFees } =
      this.utilitiesService.totalSumDvmSeatsPrices(
        this.cartService.selectedSeats
      );
    this.cartService.selectedSeatsTotalFee = totalFee;
    this.cartService.selectedSeatsTotalPrice = totalPrice;
    this.cartService.selectedSeatsTotalPriceWithFees = totalPriceWithFees;
  }

  updateCartSeatsBySection() {
    this.cartService.selectedSeatsBySection =
      this.utilitiesService.distributeSeatsBySection(
        Object.values(this.cartService.selectedSeats)
      );

    this.cartService.sortedSelectedSeatsBySectionArray =
      this.utilitiesService.sortSectionsBySeatsQuantity(
        this.cartService.selectedSeatsBySection
      );
    this.cartService.sortedSelectedSeatsBySectionArray$.next(
      this.cartService.sortedSelectedSeatsBySectionArray
    );
    // console.log('sortedSelectedSeatsBySectionArray', this.cartService.sortedSelectedSeatsBySectionArray);

    this.isolatedSeatsService.isolatedSeatsActive = false;
    this.isolatedSeatsService.lastIsolatedSeats = null;
    this.dvmService.viewer.removeNodesFromGroup(
      this.dvmService.viewer.getNodesByGroups('seat', 'pending'),
      'pending'
    );
  }

  updateSelectedSectionsOnTopview() {
    // Get selected sections from cartService
    const selectedSeatsSectionIds = Object.keys(
      this.cartService.selectedSeatsBySection
    );
    const nodeList = selectedSeatsSectionIds.map(item =>
      this.utilitiesService.nodeTranslator.get(item)
    );
    // Update selected sections on Topview
    let nodes = this.dvmService.viewer.getNodesByGroups('section', 'group1');
    this.dvmService.viewer.removeNodesFromGroup(nodes, 'group1');
    this.dvmService.viewer.addNodesToGroup(nodeList, 'group1');
    nodeList.forEach(node => {
      this.dvmService.viewer.setAvailable('section', node);
    });

    const dvmData = this.configurationService.configuration.dvmData;
    // Update selected sections on Mini Map

    if (dvmData.miniMapEnabled && dvmData.miniMapId) {
      this.dvmService.viewerMini.unselectAll();
      let success = this.dvmService.viewerMini.select(selectedSeatsSectionIds);
      let selectedMini = this.dvmService.viewerMini.getNodesByState(
        'section',
        'selected'
      );
    }
  }
}
